:root {
  --orangered: #ea3228;
  --white: #fff;
  --black: #000;
  --orange: #fcb217;
  --grey-dark: #201f1f;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--black);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--orange);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--orangered);
}

body {
  box-sizing: border-box;
  line-height: 1;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  background-color: var(--black);
  overflow-x: hidden;
  color: var(--white);
}

a,
a:hover,
a:focus,
a:focus-visible {
  text-decoration: none;
}

header {
  width: 100%;
  position: fixed;
  top: 3rem;
  z-index: 9999;
}
header svg {
  height: 2.5rem;
}
main {
  overflow: hidden;
}

article {
  margin-bottom: 12rem;
}

.services .card__orb-body {
  height: 17rem;
}

.container-fluid {
  padding: 0 5rem;
}

.nav-bar {
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: var(--black);
  border-radius: 50px;
  display: flex;
}
.nav-bar li {
  display: inline-block;
}

.nav-bar li a {
  color: var(--white);
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  padding: 1rem 1.25rem;
  overflow: hidden;
  letter-spacing: 1px;
  font-size: 0.8rem;
  transition: 0.3s ease-in-out;
}

.button__wrap {
  min-width: 300px;
  display: flex;
  align-items: end;
  flex-direction: row;
  justify-content: flex-end;
}
.button {
  display: block;
  padding: 1rem 1.5rem;
  text-align: center;
  text-transform: uppercase;
  color: var(--black);
  font-size: 0.8rem;
  font-weight: bold;
  /* background-color: #fff; */
  border-radius: 50px;
  position: relative;
  overflow: hidden;
  transition: 0.3s ease-in-out;
  width: fit-content;
}

.button::after,
.button::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: 0.2s ease-in-out;
  border-radius: 50px;
}
.button::before {
  background-color: var(--white);
  z-index: -1;
}
.button::after {
  background-color: var(--black);
  z-index: -1;
  transform: translateY(100%);
}

.button:hover::after {
  transform: translateY(0);
}
.button:hover {
  color: var(--white);
  transform: translate(0, -5px);
}
.nav-bar li a::after {
  background-color: transparent;
}
.menu-link::before {
  background-color: transparent;
}

.hero {
  width: 100%;
  height: 100vh;
  background-color: var(--black);
  background-image: url(../public/images/hero-bg.jpg);
  background-repeat: no-repeat;
  background-position: right top;
}

.hero-text {
  text-transform: uppercase;
  color: var(--white);
  margin-top: 6.25rem;
}
.hero-text p {
  font-size: 2rem;
}
.hero-text h2 {
  font-size: 4rem;
  font-weight: 800;
  color: var(--white);
}
.hero-text h2 span {
  color: var(--orange);
  display: block;
}

.hero-text-sub {
  margin-top: 6.25rem;
}
.hero-text-sub h1 {
  font-size: 3.125rem;
  color: var(--orange);
}
.hero-text-sub h3 {
  font-size: 1.5rem;
  color: var(--white);
}

.card__orb {
  position: relative;
  display: flex;
  align-items: flex-start;
  min-height: 34rem;
  background-color: var(--grey-dark);
  border-radius: 1.25rem;
  font-size: 1.125rem;
  color: var(--white);
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-end;
  overflow: hidden;
  transition: 0.3s ease-in-out all;
}
.card-orb-2 {
  display: block;
  min-height: auto;
  padding: 3rem;
  -webkit-box-shadow: -10px 10px 0px 0px rgba(246, 147, 33, 1);
  -moz-box-shadow: -10px 10px 0px 0px rgba(246, 147, 33, 1);
  box-shadow: -10px 10px 0px 0px rgba(246, 147, 33, 1);
  background: rgb(246, 147, 33);
  background: linear-gradient(
    39deg,
    rgba(246, 147, 33, 0.2) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
}

.card__no-bgcol {
  background-color: transparent;
}

.card__orb-body {
  padding: 2rem;
  z-index: 3;
  line-height: 130%;
}

.card__orb h3 {
  position: relative;
  color: var(--orange);
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 0.9rem;
}
.card__orb h3::after {
  content: "";
  display: block;
  width: 40px;
  height: 2px;
  border-radius: 5px;
  background-color: #fff;
  margin-top: 0.9rem;
}

.card__no-bgcol h3 {
  color: #fff;
}
.card__orb-img-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.card__orb-img-wrap::after,
.card__orb-img-wrap::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.card__orb-img-wrap::after {
  background: rgb(246, 147, 33);
  background: linear-gradient(
    39deg,
    rgba(246, 147, 33, 1) 0%,
    rgba(0, 0, 0, 0) 18%
  );
  z-index: -2;
}
.card__orb-img-wrap::before {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -2;
}
.card__orb-main-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -3;
  width: 100%;
  transition: 0.6s ease-in-out;
}
.card__orb-img {
  display: block;
  position: absolute;
  top: -250px;
  right: -250px;
  transition: 0.6s ease-in-out;
  z-index: 3;
}
.card__orb:hover {
  background-color: transparent;
  transform: translate(10px, -10px);
}
.card__orb:hover .card__orb-img {
  transform: scale(1.2);
}
.card__orb:hover .card__orb-main-img {
  transform: scale(1.2);
}

.seciton__mission {
  position: relative;
}

.orbit__rotate {
  position: absolute;
  left: -40%;
  top: -120%;
  z-index: -1;
  animation: rotateOrb 10s linear infinite;
}

@keyframes rotateOrb {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.swiper-wrapper {
  padding-bottom: 2rem;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-slide {
  width: 80%;
}

.swiper-pagination {
  text-align: left !important;
  left: 0 !important;
  bottom: 0 !important;
  margin-top: 1rem;
}
.swiper-pagination-bullet {
  display: block;
  background-color: #fff !important;
  width: 40px !important;
  height: 2px !important;
  border-radius: 5px !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  background-color: var(--orangered) !important;
}
.bg__grey {
  background-color: var(--grey-dark);
}
.round-5 {
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
}

.title__and-text h3 {
  font-size: 4.5rem;
}

.article__title {
  font-size: 4.5rem;
  font-weight: bold;
}
.article__title-sub {
  font-size: 1.125rem;
}
.industries {
  margin-top: 9.375rem;
}

.industries .card__grey {
  margin-bottom: 2rem;
}
.card__grey {
  padding: 1.875rem;
  border-radius: 0.675rem;
}
.card__grey-title {
  margin-top: 1.875rem;
}
.card__grey-title h3 {
  font-size: 1.875rem;
}
.seciton__mission-right .orbit__rotate {
  right: -40%;
  left: auto;
  top: -50%;
}
.table {
  border-radius: 2rem;
  overflow: hidden;
  margin-top: 6.25rem;
}
.table td {
  padding: 1.5rem;
  border-right: 1px solid #e1e1e1;
}
.team {
  margin-top: 1.875rem;
}
.team .card__grey {
  margin-bottom: 2rem;
  min-height: 18rem;
}

.careers {
  line-height: 2;
  padding: 9.375rem 3rem;
  border-radius: 2rem;
}
form {
  padding: 3.5rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(30px);
}
.form-control {
  color: var(--white);
  background-color: transparent;
  border-radius: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.form-control::placeholder {
  color: var(--white);
  opacity: 1; /* Firefox */
  padding: 0.938rem 1.563rem;
}
.form-control:focus {
  color: var(--white);
  background-color: transparent;
  border: 1px solid var(--orange);
  outline: none;
  box-shadow: none;
}
textarea.form-control {
  border-radius: 1rem;
  max-height: 5.8rem;
}
button {
  border: none;
}

footer {
  padding: 2rem 0;
  position: relative;
  overflow: hidden;
}

footer h5 {
  font-size: 3.375rem;
  font-weight: 200;
}

footer a,
footer address {
  font-size: 1.5rem;
  color: #fff;
}

.footer-orbit {
  position: absolute;
}
.footer-orbit-1 {
  top: -10%;
  z-index: -1;
}
.footer-orbit-2 {
  top: -10%;
  right: 0;
  z-index: -1;
}
.copyright {
  padding: 2rem 0;
  font-size: 0.75rem;
}
.copyright a {
  font-size: 0.75rem;
}

@media (max-width: 575.98px) {
  .orbit__rotate svg {
    width: 500px;
  }
  body {
    font-size: 14px;
  }
  .card-orb-2 {
    margin: 0 2rem;
    padding: 1rem;
  }

  header svg {
    margin-right: 3rem;
  }
  .button__wrap {
    min-width: auto;
  }
}

@media (max-width: 767.98px) {
  .hero-text h2 {
    font-size: 2.2rem;
  }
  .hero-text-sub h1 {
    font-size: 2.2rem;
  }
  .title__and-text h3,
  .article__title {
    font-size: 3.2rem;
    text-align: center;
  }
  .footer-orbit-1 svg {
    width: 400px;
  }
  .footer-orbit-2 {
    display: none;
  }
  header {
    top: 1.5rem;
  }
  header svg {
    width: auto !important;
  }
  .container-hero {
    padding-top: 6rem !important;
  }
  article {
    margin: 3.5rem 0;
  }
  .button {
    padding: 0.85rem 1.25rem;
  }
}

@media (max-width: 991.98px) {
  .container-fluid {
    padding: 0 1.25rem;
  }
  .card__orb {
    min-height: 20rem;
  }
  .orbit__rotate {
    display: none;
  }
  .industries {
    margin-top: 6rem;
  }
  .table td {
    padding: 0.5rem;
  }
}

@media (max-width: 1199.98px) {
  html {
    font-size: 80%;
  }

  .card__orb {
    margin-bottom: 2rem;
  }
  article {
    margin-bottom: 2rem;
  }
  html {
    font-size: 60%;
  }

  header svg {
    width: 180px;
  }
  .team .card__grey {
    min-height: 25rem;
  }
}

@media (max-width: 1440px) {
  html {
    font-size: 90%;
  }
  button__wrap header svg {
    width: 180px;
  }
  .button__wrap {
    min-width: 180px;
  }
  .team .card__grey {
    min-height: 21rem;
  }
}
